'use client';

import Section, { BasicSectionProps } from '@@components/Section';
import TelegramLink from '@@components/TelegramLink';
import { motion } from 'framer-motion';
import React from 'react';
import Image from 'next/image';

type CommunityProps = BasicSectionProps & {
  //
};
function Clients({ title, href }: CommunityProps) {
  return (
    <Section
      id={href}
      title={title}
      className="text-center"
      initial="hide"
      variants={{
        hide: {},
        show: {
          transition: {
            duration: 0.5,
            staggerChildren: 0.5,
          },
        },
      }}
      whileInView="show"
      viewport={{ amount: 'some', margin: '2000px 0px -200px 0px' }}
      props={{ title: { className: '!text-4xl' } }}
    >
      <motion.div
        className="mt-12 flex flex-wrap justify-around gap-8"
        variants={{
          hide: {
            // opacity: 0,
            // y: 20,
            // rotateZ: -180,
          },
          show: {
            // opacity: 1,
            // y: 0,
            transition: {
              staggerChildren: 0.25,
            },
          },
        }}
      >
        <div className="grid min-h-56 w-48 place-items-center rounded-md bg-theme-blue/25">
          <Image src="/imgs/screenshot_1.jpg" alt="client_img" width={192} height={192} />
        </div>
        <div className="grid min-h-56 w-48 place-items-center rounded-md bg-theme-blue/25">
          <Image src="/imgs/screenshot_2.jpg" alt="client_img" width={192} height={192} />
        </div>
        {/* <div className="grid h-56 w-48 place-items-center rounded-md bg-theme-blue/25">screenshot</div> */}
        {/* <div className="grid h-56 w-48 place-items-center rounded-md bg-theme-blue/25">screenshot</div> */}
        {/* <Image src={'/logo256.png'} alt="client_img" width={256} height={256} />
        <Image src={'/logo256.png'} alt="client_img" width={256} height={256} />
        <Image src={'/logo256.png'} alt="client_img" width={256} height={256} /> */}
      </motion.div>
    </Section>
  );
}

export default Clients;
